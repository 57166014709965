import { useSession } from 'next-auth/client';
import Link from 'next/link';
import React from 'react';
import Button from './Button';
import Logo from 'assets/wordmark.svg';

export default function Navbar() {
  let [session, loading] = useSession();
  let initialLoad = !session && loading;

  return (
    <div className="relative z-10 justify-between row-8">
      <Link href="/">
        <a className="py-8 font-serif text-2xl font-black text-yellow-900 opacity-75">
          <Logo className="h-6" />
        </a>
      </Link>
      <div className="py-8">
        {!initialLoad &&
          (session ? (
            <Button to="/account" appearance="primary">
              Dashboard
            </Button>
          ) : (
            <div className="row-3">
              <Button to="/login">Login</Button>
              <Button to="/signup" appearance="primary">
                Sign Up
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
}

import React, { ReactNode, useState } from 'react';
import Head from 'next/head';
import Navbar from 'components/ui/Navbar';
import Cover, { CoverProps } from 'components/print/Cover';
import Image from 'next/image';
import { times } from 'lodash';

const fakeCoverTop = {
  volumeNumber: 8,
  primaryArticle: {
    date: '2020-12-28T18:03:32.958Z',
    title: 'Interview: Dr. Akiko Iwasaki',
    author: 'Noahpinion',
    images: [
      {
        url:
          'https://cdn.substack.com/image/fetch/w_1920,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F0df9c7e0-6a1c-49a7-85df-e75797a61610_960x1200.jpeg',
        width: 960,
        height: 1200,
        isPrimary: true,
        isGenerated: false,
        scalingFactor: 2.734375
      } as any
    ],
    emailId: '41380a57-c83e-45da-a292-b9740345bf69',
    excerpt: '',
    content: '',
    isHeader: false,
    isPrimary: true,
    wordCount: 2382,
    isSecondary: false
  },
  secondaryArticle: {
    date: '2020-12-28T10:02:47.373Z',
    title: 'Best of The Cottage',
    author: 'Diana Butler Bass from The Cottage',
    images: [],
    content: '',
    emailId: '751f435a-51cb-46d5-b16f-2691c627f2e5',
    excerpt: '',
    isHeader: false,
    isPrimary: false,
    wordCount: 928,
    isSecondary: true
  },
  headerArticles: [
    {
      date: '2020-12-28T11:17:05.895Z',
      title:
        'Loujain sentenced; latest US-Saudi arms deal; ban on NYE parties;…',
      author: 'Riyadh Bureau',
      images: [],
      content: '',
      isHeader: true,
      isPrimary: false,
      isSecondary: false
    },
    {
      date: '2020-12-28T10:44:30.871Z',
      title: 'What are some of your 2021 college sports predictions?',
      author: 'Extra Points with Matt Brown',
      images: [],
      content: '',
      emailId: '',
      excerpt: '',
      isHeader: true,
      isPrimary: false,
      wordCount: 2032,
      isSecondary: false
    }
  ]
} as CoverProps;

export default function Home() {
  return (
    <>
      <Head>
        <title>Paperstack</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="flex-auto w-screen overflow-auto">
        <div className="container relative px-4 mx-auto stack-32">
          <div className="stack">
            <Navbar />

            <div className="flex flex-col py-32 -my-32 overflow-hidden md:items-center md:flex-row max-w-screen">
              <div className="relative z-10 content-center order-2 py-16 md:py-2 md:w-1/2 stack-8 md:order-1">
                <h1
                  className="font-serif text-6xl tracking-tight text-yellow-800 md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-7xl"
                  style={{ lineHeight: 0.95 }}
                >
                  Your favorite Substacks,
                  <br />
                  <span className="font-black">in your hands</span>.
                </h1>
                <h2 className="text-3xl leading-tight text-gray-700 font-serif-body">
                  Paperstack collects your favorite Substack newsletters and
                  regularly mails you a{' '}
                  <strong className="text-yellow-700">
                    beautifully formatted magazine
                  </strong>
                  .
                </h2>
              </div>
              <div
                className="relative flex flex-col justify-center flex-auto order-1 -ml-32 md:mb-0 md:-ml-48 md:-mr-96 xl:-ml-12 xl:-mr-36 2xl:-ml-12 2xl:-mr-64 md:order-2"
                style={{ height: '52rem' }}
              >
                <div className="flex flex-col flex-auto transform rounded-sm rotate-2">
                  <div className="relative flex flex-col w-full h-0 transform scale-50 bg-gray-900 rounded-sm xl:scale-75 size-magazine shadow-3xl">
                    <div>
                      <Cover
                        className="absolute inset-0 overflow-hidden"
                        {...fakeCoverTop}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto mt-12 stack-8">
            <div className="font-serif text-6xl font-bold text-center text-yellow-800">
              New, meet old.
            </div>
            <div className="max-w-lg mx-auto stack-6">
              <p className="text-2xl text-center font-serif-body">
                We're bombarded by screens all day, consuming ephemeral tweets
                and blasts and garbage.
              </p>

              <div className="max-w-sm mx-auto my-6">
                <Image
                  src="/monitor.jpg"
                  className="object-cover rounded-sm"
                  width={500}
                  height={300}
                />
              </div>

              <p className="text-2xl text-center font-serif-body">
                But you can break away.
              </p>
              <p className="text-2xl text-center font-serif-body">
                Grab on to something <em>permanent</em>.
              </p>
              <p className="text-2xl text-center font-serif-body">
                Go deep on something startlingly new and unfamiliar.
              </p>

              <p className="text-2xl text-center font-serif-body">
                There's a certain magic to <em>holding</em>.
              </p>
              <p className="text-2xl text-center font-serif-body">
                To flipping through pages.
              </p>
              <p className="text-2xl text-center font-serif-body">
                To sitting and <em>soaking</em> in ideas.
              </p>

              <div className="justify-center my-10 row-2">
                <div className="w-1 h-1 bg-black rounded-full opacity-10" />
                <div className="w-1 h-1 bg-black rounded-full opacity-10" />
                <div className="w-1 h-1 bg-black rounded-full opacity-10" />
              </div>

              <p className="text-2xl text-center font-serif-body">
                What will you learn?
              </p>
            </div>
          </div>

          <div className="overflow-hidden">
            <div
              className="flex"
              style={{
                animation: 'marquee 60s linear infinite alternate'
              }}
            >
              {times(2, i => (
                <div key={i} className="flex flex-none">
                  <NewsletterCard
                    name="Riyadh Bureau"
                    description="News, analysis, and commentary on Saudi Arabia"
                    author="Ahmed Al Omran"
                    image="https://cdn.substack.com/image/fetch/w_196,h_196,c_fill,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2Fdb9d8cbd-e11c-41d2-8a51-0f8ec8ba19e1_640x640.png"
                  />
                  <NewsletterCard
                    name="Age of Invention"
                    description="Tales from the history of innovation"
                    author="Anton Howes"
                    image="https://cdn.substack.com/image/fetch/w_196,h_196,c_fill,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F26f8138c-6f4f-4c22-86d5-76194beccb5e_256x256.png"
                  />
                  <NewsletterCard
                    name="Extra Points"
                    description="A newsletter on the off-the-field forces that shape college sports"
                    author="Matt Brown"
                    image="https://cdn.substack.com/image/fetch/w_196,h_196,c_fill,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2Fd9447e49-201a-4e46-9daa-e2beac889f0c_1280x1280.png"
                  />
                  <NewsletterCard
                    name="Noahpinion"
                    description="Economics, rabbits, and distration from productive activity"
                    author="Noah Smith"
                    image="https://cdn.substack.com/image/fetch/w_196,h_196,c_fill,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F04281755-2cd6-42e5-a496-e69153abebb2_281x281.png"
                  />
                  <NewsletterCard
                    name="Process"
                    description="A weekly newsletter about photography and finding your voice"
                    author="Wesley Verhoeve"
                    image="https://cdn.substack.com/image/fetch/w_196,h_196,c_fill,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F6a09ac88-690c-453b-b5a6-f345c9f9d40a_256x256.png"
                  />
                  <NewsletterCard
                    name="The Reading"
                    description="An offering of creative advice by writer and critic Yanyi"
                    author="Yanyi"
                    image="https://cdn.substack.com/image/fetch/w_196,h_196,c_fill,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2Ff9de9bfe-8c8b-45d1-9316-2ec77c7e0605_256x256.png"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="container mx-auto mt-12 stack-8">
            <div className="font-serif text-6xl font-bold text-center text-yellow-800">
              How it works
            </div>
            <div className="grid grid-cols-2 gap-12">
              <ol className="text-2xl font-semibold list-decimal stack-6 font-serif-body">
                <li className="py-6 rounded-lg">
                  <div className="mb-2 text-black">
                    Send us your Substack newsletters
                  </div>
                  <p className="font-sans text-lg font-normal text-gray-700">
                    When you subscribe, you'll get a unique Paperstack email
                    which you can selectively{' '}
                    <strong className="font-semibold underline">
                      foward individual Substack emails
                    </strong>{' '}
                    to, or you can{' '}
                    <strong className="font-semibold underline">
                      subscribe to a Substack with your Paperstack email
                      directly
                    </strong>{' '}
                    to ensure every newsletter gets printed. Don't worry, we'll
                    forward you any original emails you send us.
                  </p>
                </li>

                <li className="py-6 rounded-lg">
                  <div className="mb-2 text-black">Build your issue</div>
                  <p className="font-sans text-lg font-normal text-gray-700">
                    We'll collect your Substack emails as they arrive, adding
                    them to your next issue.{' '}
                    <strong className="font-semibold underline">
                      We'll print a new issue as often as you'd like
                    </strong>
                    : try a weekly cadence to build your own custom current
                    events magazine, or collect the highlights and print once a
                    quarter.
                  </p>
                </li>

                <li className="py-6 rounded-lg">
                  <div className="mb-2 text-black">Enjoy your magazine</div>
                  <p className="font-sans text-lg font-normal text-gray-700">
                    We'll format and layout your issue, print it on high quality
                    premium paper, with gorgeous full bleed imagery and a
                    perfect bound spine.{' '}
                    <strong className="font-semibold underline">
                      Every issue is reviewed by hand before printing.
                    </strong>
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div className="container w-full max-w-xl mx-auto mt-12 stack-8">
            <div className="font-serif text-6xl font-bold text-center text-yellow-800">
              Questions?
            </div>
            <Question question="How much does Paperstack cost?">
              You'll only be charged when an issue is actually printed. Each
              issue is $19, which includes printing & standard shipping. Very
              long issues or faster shipping can be extra.
            </Question>
            <Question question="How do I let others subscribe to my Paperstack magazine?">
              We'd love to make this happen.{' '}
              <a
                href="mailto:team@paperstack.co"
                className="text-blue-500 underline"
              >
                Get in touch us!
              </a>
            </Question>
          </div>

          <div className="w-full h-32" />
        </div>
      </div>
    </>
  );
}

function NewsletterCard(props: {
  name: string;
  author: string;
  description: string;
  image: string;
}) {
  return (
    <div className="flex items-center flex-none w-full max-w-xs p-3 mr-4 border border-black rounded-sm border-opacity-10">
      <div className="flex-none w-24 h-24 mr-4">
        <Image
          className="flex-none object-cover rounded-sm"
          src={props.image}
          width={24 * 4}
          height={24 * 4}
        />
      </div>
      <div className="content-center flex-auto leading-tight stack-1">
        <div className="stac">
          <div className="font-serif text-xl font-bold">{props.name}</div>
          <div className="font-sans text-xs font-medium opacity-50">
            {props.author}
          </div>
        </div>
        <div className="font-sans text-sm font-medium opacity-75">
          {props.description}
        </div>
      </div>
    </div>
  );
}

function Question(props: { question: string; children: ReactNode }) {
  let [open, setOpen] = useState(false);

  return (
    <div className="stack">
      <div
        className="font-serif text-2xl font-semibold cursor-pointer"
        onClick={() => setOpen(s => !s)}
      >
        {props.question}
      </div>
      {open && <div className="py-3 font-sans text-base">{props.children}</div>}
    </div>
  );
}

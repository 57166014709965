import React from 'react';
import classnames from 'classnames';
import Logo from 'assets/wordmark.svg';
import { byline } from 'utils/byline';
import { Article } from './Issue';

export interface CoverProps {
  className?: string;
  volumeNumber: number;
  primaryArticle: Article;
  secondaryArticle?: Article;
  headerArticles?: Article[];
}

export default function Cover(props: CoverProps) {
  return (
    <div
      className={classnames(
        'flex flex-col flex-auto overflow-hidden',
        props.className
      )}
    >
      <img
        className="absolute inset-0 object-cover size-magazine"
        src={props.primaryArticle.images.find(i => i.isPrimary)!.url}
      />
      <div
        className="absolute inset-0"
        style={{
          background: [
            'radial-gradient(202% 106% ellipse at 100% 100%, transparent 78%, rgba(0,0,0,0.5))',
            'radial-gradient(166% 97% at 100% 0%, transparent 61%, rgba(0,0,0,0.6))',
            'radial-gradient(161% 100% at 0% 100%, transparent 80%, rgba(0,0,0,0.1))'
          ].join(',')
        }}
      />
      <div
        className="absolute inset-0 border-4 border-yellow-50"
        style={{ borderWidth: '1rem' }}
      />
      <div className="flex justify-between px-4 py-8">
        <div className="m-10 stack-1">
          <div className="font-serif text-4xl font-black leading-none text-white opacity-75">
            <Logo className="h-10" />
          </div>
          <div className="relative font-serif text-lg text-white opacity-50">
            Vol. {props.volumeNumber}
          </div>
        </div>
        <div className="relative justify-end w-2/3 pt-1 mr-2 -mt-px row-3">
          {props.headerArticles?.slice(0, 3).map((article, i) => (
            <div key={i} className="content-start stack-3">
              <div className="h-1 bg-yellow-50" />
              <div className="pr-4 leading-tight stack-0">
                <div className="text-white opacity-50 text-2xs">
                  {byline(article)}
                </div>
                <div className="text-sm font-bold leading-tight text-white">
                  {article.title}
                </div>
                <div className="text-xs text-white opacity-75">
                  {article.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-12 mt-auto">
        {props.secondaryArticle && (
          <div className="mb-8 stack-3">
            <div className="items-center justify-start row-2">
              <div className="leading-none stack-0">
                <div className="text-base text-white opacity-50">
                  {byline(props.secondaryArticle)}
                </div>
                <div className="font-serif text-4xl font-bold text-white opacity-80">
                  {props.secondaryArticle.title}
                </div>
                {props.secondaryArticle.description && (
                  <div className="text-lg text-white opacity-60">
                    {props.secondaryArticle.description}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="stack-1">
          <div
            className="relative z-10 font-serif font-bold tracking-tight text-white text-8xl"
            style={{ lineHeight: 0.85 }}
          >
            {props.primaryArticle.title}
          </div>
          <div className="text-lg text-white opacity-50">
            {byline(props.primaryArticle)}
          </div>
        </div>
      </div>
    </div>
  );
}
